var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tablePart"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],ref:"table",attrs:{"data":_vm.orderListData,"border":""},on:{"selection-change":_vm.selectionChange}},[_vm._l((_vm.tableFormData),function(ref,index){
var type = ref.type;
var label = ref.label;
var prop = ref.prop;
var width = ref.width;
return [(type == 'selection')?_c('el-table-column',{key:index,attrs:{"type":type}}):(label == '物流到期时间')?_c('el-table-column',{key:index,attrs:{"min-width":width,"label":label},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(parseInt(scope.row.expressExpireTime) <= 3)?_c('div',[_vm._v(" "+_vm._s(scope.row.expressExpireTime)+" "),_c('el-tag',{staticClass:"tixing",attrs:{"type":"danger","size":"mini"}},[_vm._v("临近提醒")])],1):_c('div',[_vm._v(" "+_vm._s(scope.row.expressExpireTime)+" ")])]}}],null,true)}):(label == '刀版图状态')?_c('el-table-column',{key:index,attrs:{"min-width":width,"label":label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isKnifeLayoutCreated = ref.row.isKnifeLayoutCreated;
return [_vm._v(" "+_vm._s(_vm.KnifeLayout(isKnifeLayoutCreated))+" ")]}}],null,true)}):(label == '时间')?_c('el-table-column',{key:index,attrs:{"min-width":width,"label":label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v("导入时间： "+_vm._s(row.importTime))]),(row.orderStatus === 42)?_c('div',[_vm._v("排单时间："+_vm._s(row.sendTime || '暂无'))]):_vm._e(),(row.orderStatus === 70)?_c('div',[_c('div',[_vm._v("排单时间："+_vm._s(row.sendTime || '暂无'))]),_c('div',[_vm._v("发货时间："+_vm._s(row.deliveryTime || '暂无'))])]):_vm._e(),(row.orderStatus === 80)?_c('div',[_vm._v("取消时间："+_vm._s(row.cancelTime || '暂无'))]):_vm._e(),(row.orderStatus === 72)?_c('div',[_vm._v("退货申请时间："+_vm._s(row.returnApplyTime || '暂无'))]):_vm._e(),(row.orderStatus === 73)?_c('div',[_c('div',[_vm._v("退货申请时间："+_vm._s(row.returnApplyTime || '暂无'))]),_c('div',[_vm._v("退货完成时间："+_vm._s(row.returnCompleteTime || '暂无'))])]):_vm._e()]}}],null,true)}):(label == '物流渠道')?_c('el-table-column',{key:index,attrs:{"min-width":width,"label":label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.expressName(row))+" ")]}}],null,true)}):(label == '订单状态')?_c('el-table-column',{key:index,attrs:{"min-width":width,"label":label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var orderStatus = ref.row.orderStatus;
return [_vm._v(" "+_vm._s(_vm.ORDER_STATUS_LIST[orderStatus])+" ")]}}],null,true)}):(label == '运单号')?_c('el-table-column',{key:index,attrs:{"min-width":width,"label":label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getExpressWaybillCode(row))+" ")]}}],null,true)}):(label == '订单类别')?_c('el-table-column',{key:index,attrs:{"min-width":width,"label":label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.orderType === 2 ? 'FBA订单' : '普通订单'))]}}],null,true)}):(label == '业务员')?_c('el-table-column',{key:index,attrs:{"min-width":width,"label":label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.userName)+" "),(row.userStatus === 2)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("(已注销)")]):_vm._e()]}}],null,true)}):(label == '操作')?_c('el-table-column',{key:index,attrs:{"min-width":width,"label":label,"fixed":"right","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scoped){return [(_vm.permission.update)?_c('changeStateBtn',{attrs:{"data":scoped.row,"sup_this":_vm.sup_this}}):_vm._e(),(_vm.permission.edit && _vm.canEdit(scoped.row))?_c('Edit',{attrs:{"data":scoped.row,"type":1,"id":scoped.row.id,"sup_this":_vm.sup_this,"orderStatus":_vm.orderStatus}},[_vm._v(" 编辑 ")]):_vm._e(),(_vm.permission.detail)?_c('Edit',{attrs:{"data":scoped.row,"isProTable":true,"id":scoped.row.id}},[_vm._v(" 查看详情 ")]):_vm._e()]}}],null,true)}):_c('el-table-column',{key:index,attrs:{"prop":prop,"min-width":width,"label":label}})]}),_c('template',{slot:"empty"},[_vm._v("暂无数据")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }