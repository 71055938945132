<template>
  <div class="tablePart">
    <el-table ref="table" v-loading="tableLoading" :data="orderListData" border @selection-change="selectionChange">
      <template v-for="({ type, label, prop, width }, index) in tableFormData">
        <el-table-column :key="index" v-if="type == 'selection'" :type="type"></el-table-column>

        <el-table-column :key="index" v-else-if="label == '物流到期时间'" :min-width="width" :label="label">
          <template slot-scope="scope">
            <div v-if="parseInt(scope.row.expressExpireTime) <= 3">
              {{ scope.row.expressExpireTime }}
              <el-tag type="danger" size="mini" class="tixing">临近提醒</el-tag>
            </div>
            <div v-else>
              {{ scope.row.expressExpireTime }}
            </div>
          </template>
        </el-table-column>

        <el-table-column :key="index" v-else-if="label == '刀版图状态'" :min-width="width" :label="label">
          <template slot-scope="{ row: { isKnifeLayoutCreated } }">
            {{ KnifeLayout(isKnifeLayoutCreated) }}
          </template>
        </el-table-column>

        <el-table-column :key="index" v-else-if="label == '时间'" :min-width="width" :label="label">
          <template slot-scope="{ row }">
            <div>导入时间： {{ row.importTime }}</div>
            <div v-if="row.orderStatus === 42">排单时间：{{ row.sendTime || '暂无' }}</div>
            <div v-if="row.orderStatus === 70">
              <div>排单时间：{{ row.sendTime || '暂无' }}</div>
              <div>发货时间：{{ row.deliveryTime || '暂无' }}</div>
            </div>
            <div v-if="row.orderStatus === 80">取消时间：{{ row.cancelTime || '暂无' }}</div>
            <div v-if="row.orderStatus === 72">退货申请时间：{{ row.returnApplyTime || '暂无' }}</div>
            <div v-if="row.orderStatus === 73">
              <div>退货申请时间：{{ row.returnApplyTime || '暂无' }}</div>
              <div>退货完成时间：{{ row.returnCompleteTime || '暂无' }}</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column :key="index" v-else-if="label == '物流渠道'" :min-width="width" :label="label">
          <template slot-scope="{ row }">
            {{ expressName(row) }}
          </template>
        </el-table-column>

        <el-table-column :key="index" v-else-if="label == '订单状态'" :min-width="width" :label="label">
          <template slot-scope="{ row: { orderStatus } }">
            {{ ORDER_STATUS_LIST[orderStatus] }}
          </template>
        </el-table-column>

        <el-table-column :key="index" v-else-if="label == '运单号'" :min-width="width" :label="label">
          <template slot-scope="{ row }">
            {{ getExpressWaybillCode(row) }}
          </template>
        </el-table-column>

        <el-table-column :key="index" v-else-if="label == '订单类别'" :min-width="width" :label="label">
          <template slot-scope="{ row }"> {{ row.orderType === 2 ? 'FBA订单' : '普通订单' }}</template>
        </el-table-column>
        <el-table-column :key="index" v-else-if="label == '业务员'" :min-width="width" :label="label">
          <template slot-scope="{ row }">
            {{ row.userName }} <span v-if="row.userStatus === 2" style="color: red">(已注销)</span>
          </template>
        </el-table-column>

        <el-table-column
          :key="index"
          v-else-if="label == '操作'"
          :min-width="width"
          :label="label"
          fixed="right"
          align="center"
        >
          <template slot-scope="scoped">
            <changeStateBtn :data="scoped.row" :sup_this="sup_this" v-if="permission.update"></changeStateBtn>
            <Edit
              v-if="permission.edit && canEdit(scoped.row)"
              :data="scoped.row"
              :type="1"
              :id="scoped.row.id"
              :sup_this="sup_this"
              :orderStatus="orderStatus"
            >
              编辑
            </Edit>
            <Edit :data="scoped.row" :isProTable="true" :id="scoped.row.id" v-if="permission.detail"> 查看详情 </Edit>
          </template>
        </el-table-column>

        <el-table-column :key="index" v-else :prop="prop" :min-width="width" :label="label"></el-table-column>
      </template>

      <template slot="empty">暂无数据</template>
    </el-table>
  </div>
</template>

<script>
import { tableFormDataObj } from './tablePartData'
import changeStateBtn from './changeStateBtn'
import orderDetile from './orderDetile'
import editBtn from './editBtn'
import Edit from './edit'
import {
  WAIT_RELATION,
  WAIT_GENERATE_WAYBILL,
  WAIT_DISPATCH,
  IN_DISPATCH,
  ORDER_STATUS_LIST,
  getExpressWaybillCode
} from '@/utils/constant'
import { deepClone, getLabel } from '@/components/avue/utils/util'
import { parseTime } from '@/utils'

export default {
  components: {
    changeStateBtn,
    orderDetile,
    editBtn,
    Edit
  },
  props: {
    orderListData: {
      type: Array,
      required: true,
      default: [],
      factoryData: []
    },
    permission: {
      type: Object
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    sup_this: {
      type: Object,
      required: true
    },
    orderStatus: {
      type: String
    }
  },
  data() {
    return {
      ORDER_STATUS_LIST
    }
  },
  computed: {
    canEdit() {
      return (row) => {
        const { orderStatus } = row
        const temArr = [WAIT_RELATION, WAIT_GENERATE_WAYBILL, WAIT_DISPATCH, IN_DISPATCH]
        return true
        return temArr.includes(orderStatus)
      }
    },
    KnifeLayout() {
      return (data) => {
        if (data == '0') {
          return '未全部生成'
        } else {
          return '已全部生成'
        }
      }
    },
    expressCompany() {
      return (data) => {
        switch (data) {
          case 1:
            return '申通'
            break
          case 4:
            return '顺丰'
            break
          case 2:
            return 'fedex'
            break
          case 6:
            return '极兔'
            break
        }
      }
    },
    tableFormData({ orderStatus }) {
      console.log('tableFormDataObj[orderStatus]', tableFormDataObj[orderStatus])
      return tableFormDataObj[orderStatus] || tableFormDataObj.default
    }
  },
  methods: {
    getLabel,
    parseTime,
    getExpressWaybillCode,
    expressName(scoped) {
      if (scoped.orderType == 3) {
        return this.logistics_service_name(scoped.expressThird)
      }
      return scoped.expressShippingMethodCnName || scoped.expressCompanyName
    },
    logistics_service_name(expressThird) {
      try {
        return JSON.parse(expressThird)?.logistics_service_name
      } catch (error) {
        return '暂无'
      }
    },
    selectionChange(selection) {
      this.factoryData = selection
      this.$emit('takeFactory', selection)
    }
  }
}
</script>

<style lang="scss" scoped>
.tablePart {
  margin-top: 20px;
}
.tixing {
  margin-left: 10px;
}
</style>
